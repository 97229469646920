<template>
  <div>
   
    <router-view></router-view>
  </div>
</template>

<script>
 import Base from '@/config/Mixins_base';
  import {
    BRow,
    BCol,
    BButton
  } from 'bootstrap-vue'

  export default {
    components: { 
      BRow,
      BCol,
      BButton
    },
    mounted(){
      this.cek_token();
    },
    mixins:[Base]

  }
</script>

<style>

</style>